<template>
    <span style="padding: 1px">
        <b-button v-on:click="toggle" v-bind:size="size" style="border: 1px solid gray;"
                  v-bind:style="{color: 'white', backgroundColor: visible ? 'darkgray' : 'black' }">
            {{label}}</b-button>
    </span>
</template>

<script>
    export default {
        mounted(){
           // console.log("profit-loss-button.vue mounted() starting. this.namespace=", this.namespace, " this.indicator=", this.indicator);
            if(this.indicator === "p/l"){
                this.$store.commit(this.namespace + '/indicators/set', {indicator: "p/l", key: "visible", value: false});
            }
        },
        props: {
            namespace: {
                type: String
            },
            size: {
                type: String,
                default: 'sm'
            },
            indicator: {
                type: String
            },
            label: {
                type: String
            }
        },
        computed: {
            visible(){
                return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === this.indicator).visible;
            }
        },
        methods: {
            toggle: function () {
                console.log("toggle() starting.");
               this.$store.commit(this.namespace + '/indicators/set', {indicator: this.indicator, key: "visible", value: !this.visible});
            }
        }
    }
</script>
